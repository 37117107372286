export interface OrderCreationRequest {
    developer_payload: string
    payment_method_id: string
}

export enum OrderState {
    Overdue = "OVERDUE",
    Failed = "FAILED",
    Unknown = "UNKNOWN",
    Payed = "PAYED",
    Consumed = "CONSUMED",
    Canceled = "CANCELED",
    Confirmed = "CONFIRMED",
    Created = "CREATED",
    PaymentCreated = "PAYMENTCREATED",
}

export interface Product {
    amount: number
    full_name: string
    price: number
    sku: number
}

export interface Order {
    app: string
    cart: Product[]
    error: string
    error_code: number
    ext: object
    order_id: string
    order_token: string
    payment_id: string
    payment_method_id: string
    state: OrderState
}

export type RequestProperties = {
    GaimpInit: {};
    GaimpGetProducts: {};
    GaimpGetOrders: {};
    GaimpGetOrder: { order_id: string };
    GaimpCreateOrder: { sku: string, request: { developer_payload: string } };
};

export type ResponseProperties = {
    GaimpInit: {};
    GaimpGetProducts: { products: Product[] };
    GaimpGetOrders: { orders: Order[] };
    GaimpGetOrder: { order: Order };
    GaimpCreateOrder: { order: Order };
}

type EventReceiveNames<
  T extends keyof RequestProperties,
  R extends string,
  F extends string,
> = Record<T, { result: R; failed: F }>;

/**
 * Map of event names.
 */
export type ReceiveEventMap = 
  EventReceiveNames<
    'GaimpInit',
    'GaimpInitResult',
    'GaimpInitFailed'
  > &
  EventReceiveNames<
    'GaimpGetProducts',
    'GaimpGetProductsResult',
    'GaimpGetProductsFailed'
  > &
  EventReceiveNames<
    'GaimpGetOrders',
    'GaimpGetOrdersResult',
    'GaimpGetOrdersFailed'
  > &
  EventReceiveNames<
    'GaimpGetOrder',
    'GaimpGetOrderResult',
    'GaimpGetOrderFailed'
  > &
  EventReceiveNames<
    'GaimpCreateOrder',
    'GaimpCreateOrderResult',
    'GaimpCreateOrderFailed'
  >