import { BridgeSubscribeHandler, GAIMPBridge, RequestIdProperty, RequestMethodNames, RequestProps, ResponseData } from './types'

/** Is the client side runtime environment */
export const IS_CLIENT_SIDE = typeof window !== 'undefined';

/** Is the runtime environment an Android app */
export const IS_ANDROID_WEBVIEW = Boolean(IS_CLIENT_SIDE && (window as any).AndroidBridge);

/** Is the runtime environment a browser */
export const IS_WEB = IS_CLIENT_SIDE;

/** Web VK Bridge interface. */
const webBridge: { postMessage?: (message: any, targetOrigin: string) => void } | undefined = IS_WEB
  ? parent
  : undefined;


/**
 * Creates counter interface.
 */
function createCounter() {
  return {
    current: 0,
    next() {
      return ++this.current;
    },
  };
}
  
/**
 * Creates interface for resolving request promises by request id's (or not).
 *
 * @param instanceId Uniq bridge instance ID.
 */
function createRequestResolver(instanceId: string) {
  /**
   * @prop resolve Resolve function.
   * @prop reject Reject function.
   */
  type PromiseController = {
      resolve: (value: any) => any;
      reject: (reason: any) => any;
  };

  const counter = createCounter();
  const promiseControllers: Record<number | string, PromiseController | null> = {};

  return {
    /**
     * Adds new controller with resolve/reject methods.
     *
     * @param controller Object with `resolve` and `reject` functions
     * @param customId Custom `request_id`
     * @returns New request id of the added controller.
     */
    add(controller: PromiseController, customId?: number | string): number | string {
      const id = customId != null ? customId : `${counter.next()}_${instanceId}`;

      promiseControllers[id] = controller;

      return id;
    },

    /**
     * Resolves/rejects an added promise by request id and the `isSuccess`
     * predicate.
     *
     * @param requestId Request ID.
     * @param data Data to pass to the resolve- or reject-function.
     * @param isSuccess Predicate to select the desired function.
     */
    resolve<T>(requestId: number | string, data: T, isSuccess: (data: T) => boolean) {
      const requestPromise = promiseControllers[requestId];

      if (requestPromise) {
        if (isSuccess(data)) {
          requestPromise.resolve(data);
        } else {
          requestPromise.reject(data);
        }

        promiseControllers[requestId] = null;
      }
    },
  };
}

export function promisifySend(
    sendEvent: <K extends RequestMethodNames>(
        method: K,
        props?: RequestProps<K> & RequestIdProperty,
    ) => void,
    subscribe: (handler: BridgeSubscribeHandler) => void,
    instanceId: string,
) {
  const requestResolver = createRequestResolver(instanceId);

  subscribe((event) => {
    console.log("Request resolver got data: " + JSON.stringify(event))
    
    if (!event.detail || !event.detail.data || typeof event.detail.data !== 'object') {
      return;
    }

    // There is no request_id in receive-only events, so we check its existence.
    if ('request_id' in event.detail.data) {
      const { request_id: requestId, ...data } = event.detail.data;

      if (requestId) {
        requestResolver.resolve(requestId, data, (data) => !('error_type' in data));
      }
    }
  })

  return function promisifiedSend<K extends RequestMethodNames>(
    method: K,
    props: RequestProps<K> & RequestIdProperty = {} as RequestProps<K> & RequestIdProperty, // eslint-disable-line @typescript-eslint/consistent-type-assertions
  ): Promise<K extends RequestMethodNames ? ResponseData<K> : void> {
    return new Promise((resolve, reject) => {
      const requestId = requestResolver.add({ resolve, reject }, props.request_id);

      sendEvent(method, {
        ...props,
        request_id: requestId,
      });
    });
  };
}

export function createInstanceId() {
  const allNumbersAndLetters = 36;
  const positionAfterZeroAnDot = 2;
  const keyLength = 3;
  return Math.random()
    .toString(allNumbersAndLetters)
    .substring(positionAfterZeroAnDot, positionAfterZeroAnDot + keyLength);
}

export function createGaimpBridge(): GAIMPBridge {
    const subscribers: BridgeSubscribeHandler[] = [];

    /** Uniq instance ID */
    const instanceId = createInstanceId();

    function send<K extends RequestMethodNames>(
        method: K,
        props?: RequestProps<K> & RequestIdProperty,
    ) {
      if (webBridge && typeof webBridge.postMessage === 'function') {
        webBridge.postMessage(
          {
              method: method,
              props: props,
              request_id: props?.request_id,
          },
          '*',
        )
      }
    }

    function subscribe(handler: BridgeSubscribeHandler) {
      subscribers.push(handler);
    }

    function unsubscribe(handler: BridgeSubscribeHandler) {
        const index = subscribers.indexOf(handler);

        if (index > -1) {
            subscribers.splice(index, 1);
        }
    }

    function handleEvent(event: any) {
        console.log("Bridge got event from parent: " + JSON.stringify(event.data));

        let bridgeEventData = event?.data;
        if (!bridgeEventData) {
          return;
        }
    
        const { type, data } = bridgeEventData;
        
        console.log("Bridge type " + type + " data " + data);

        if (!type) {
          return;
        }
    
        [...subscribers].map((fn) => fn({ detail: { type, data } }));
      }

    if (typeof window !== 'undefined' && 'addEventListener' in window) {
        window.addEventListener('message', handleEvent);
    }
    
    return {
        send: promisifySend(send, subscribe, instanceId),
        subscribe,
        unsubscribe
    }
}